<template>
  <div :class="['ui-date-picker', { error: error, required: requiredField }]">
    <div class="ui-date-picker__label">{{  error ? errorText : label }}</div>
    <div class="ui-date-picker__inner">
      <Datepicker
          v-model="model"
          format="DD.MM.YYYY"
          :placeholder="placeholder"
          :disabled-date="disabledRange"
          :range="isRange"
          ref="datePicker"
          :get-classes="getClasses"
      />
      <img @click="$refs.datePicker.openPopup()" class="ui-date-picker__inner-icon" src="@/assets/svg/icon-date-black.svg" alt="" />
    </div>
  </div>

</template>

<script>
import Datepicker from 'vue2-datepicker'
import "vue2-datepicker/index.css";
import { getDate } from "@/utils/general";
export default {
  name: "UiDatePicker",
  components: {
    Datepicker
  },

  props: {
    value: { type: [Array, Date, Boolean] },
    error: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
    eventsDates: {
      type: Array,
      default: () => []
    },
    label: String,
    errorText: String,
    placeholder: String,
    disabledRange: Function,
  },

  data() {
    return {
      getDate,
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    }
  },

  methods: {
    getClasses(date) {
      if (this.eventsDates.find(v => v === this.getDate(date))) {
        return "highlight";
      }
      return "";
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-date-picker {
  width: 100%;

  &.required {

    .ui-date-picker__label:after {
      content: '*';
      color: #E21F1F;
    }
  }

  &.error {

    .ui-date-picker{

      &__label {
        animation: 0.25s shake;
        color: #E21F1F;
      }

      &__inner {

        ::v-deep input {
          border-color: #E21F1F;
        }
      }
    }
  }


  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin: 0 0 10px 30px;
  }

  &__inner {
    position: relative;

    &-icon {
      position: absolute;
      right: 10px;
      bottom: 8px;
      cursor: pointer;
    }
  }

  ::v-deep input {
    width: 100%;
    height: 40px;
    border: 1px solid #9A9A9A;
    border-radius: 200px;
    outline: none;
    padding: 8px 10px 8px 15px;
    color: #343432;
    font-size: 12px;
  }
}

::v-deep.mx-datepicker {
  width: 100%;
  svg {
    display: none !important;
  }
}
</style>
